<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title
        ><h4 class="font-weight-light">DEPOSIT SLIP & PAYMENT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-stepper v-model="e1" non-linear :key="is_reset">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              {{ step_one_title }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              {{ step_two_title }}
            </v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12" color="grey lighten-1">
                <deposit-slips
                  v-on:data="deposit_details"
                ></deposit-slips>
              </v-card>
              <v-card-actions>
                <v-alert color="warning" text class="mb-0" v-show="alert">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="!saving"
                  color="primary"
                  @click="save_deposit_slip"
                >
                  Save changes
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
                <!-- alert -->

              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="mb-12" color="grey lighten-1">
                <sporo-transaction-tobe-deposit
                  v-on:data="payment_data"
                  v-on:remove_data="remove_data"
                  :encoded_data="encoded_data"
                  :deposit_slips="deposit_slips"
                  :amount_formated="formatPrice(deposit_slips.amount)"
                  :balance_formated="formatPrice(deposit_slips.balance)"
                ></sporo-transaction-tobe-deposit>
              </v-card>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary" @click="initializeData(true)">
                  Done
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import DepositSlips from '@/views/ggc_loan_interface/payment_interface/Step1DepositSlips'
  import SporoTransactionTobeDeposit
    from '@/views/ggc_loan_interface/payment_interface/Step2Payment'
  import moment from "moment";

  const initialState = () => {
    return {
      saving: true,
      alert: false,
      alert_message: '',
      is_validated_dep: false,

      is_deduction_payment: false,
      is_reset: false,
      e1: 1,
      step_one_title: 'DEPOSIT SLIP',
      step_two_title: 'PAYMENT',

      bank_id: 0,
      date_of_deposit: '',
      time_of_deposit: '',
      amount: 0,
      balance: 1,
      deposit_slips: {'bank_depositories': ''},
      encoded_data: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
      DepositSlips,
      SporoTransactionTobeDeposit,
    },
    setup() {
      return {
        confirmation_data: {},
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initializeData(false)
    },
    watch: {
      e1: function () {
        if (this.e1 > 2) {
          this.reset()
        }
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('ggc_loan_deposit_slips', ['create_ggc_deposit_slip', 'get_latest_ggc_loans_deposit_slips']),
      ...mapActions('ggc_loan_payments', ['ggc_loan_payment_load_to_deposit', 'ggc_loan_payment_remove_from_deposit', 'ggc_deduction_payment_load_to_deposit', 'ggc_deduction_payment_remove_from_deposit']),
      reset() {
        this.is_reset = true
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          Object.assign(this.$data, initialState())
        }, 100)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      date_format(value) {
        return moment(value)
      },
      deposit_details(value) {
        this.is_validated_dep = value.is_validated
        this.bank_id = value.bank_id
        this.date_of_deposit = value.date_of_deposit
        this.amount = value.amount
        this.time_of_deposit = value.time_of_deposit
        this.is_deduction_payment = value.is_deduction_payment
      },
      initializeData(value) {
        this.encoded_data =[]
        const data = new FormData()
        data.append('maker_id', this.user_id);
        this.get_latest_ggc_loans_deposit_slips(data)
          .then(response => {
            if (response.data != null && response.data != '') {
              this.deposit_slips = response.data
              if (this.deposit_slips.payment.length > 0) {
                this.encoded_data = this.deposit_slips.payment
              }
              if (this.deposit_slips.deduction_payment.length > 0) {
                this.encoded_data = this.deposit_slips.deduction_payment
              }
              this.balance = this.deposit_slips.balance
              if (this.balance >= 1) {
                this.e1 = 2
              } else {
                if (value) {
                  this.e1 = 1
                }
                this.saving = false
              }
            } else {
              this.e1 = 1
              this.saving = false
            }
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      },
      payment_data(value) {
        const data = new FormData()
        data.append('deposit_slip_id', value.deposit_slip_id);
        data.append('balance', value.balance);
        if (value.balance <= -100) {
          this.change_snackbar({
            show: true,
            color: 'error',
            text: 'PLEASE CHECK THE BALANCE AND TRY AGAIN',
          })
        } else {
          if (value.is_deduction) {
            data.append('loan_id', value.loan_id);
            data.append('particulars', value.particulars);
            data.append('details', value.details);
            data.append('amount', value.amount);
            this.ggc_deduction_payment_load_to_deposit(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.initializeData(false)
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          } else {
            data.append('id', value.id);
            data.append('or_date', value.or_date);
            data.append('or_no', value.or_no);
            this.ggc_loan_payment_load_to_deposit(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.initializeData(false)
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          }
        }
      },
      remove_data(value) {
        const data = new FormData()
        data.append('balance', value.balance);
        data.append('id', value.id);
        data.append('deposit_slip_id', value.deposit_slip_id);
        if (value.is_deduction) {
          this.ggc_deduction_payment_remove_from_deposit(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.initializeData(false)
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.ggc_loan_payment_remove_from_deposit(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.initializeData(false)
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        }
      },
      save_deposit_slip() {
        this.saving = true
        this.alert = false
        if (this.is_validated_dep) {
          const data = new FormData()
          data.append('bank_id', this.bank_id);
          data.append('maker_id', this.user_id);
          data.append('date_of_deposit', this.date_of_deposit);
          data.append('time_of_deposit', this.time_of_deposit);
          data.append('amount', this.amount);
          data.append('is_payment', !this.is_deduction_payment ? 1 : 0);
          this.create_ggc_deposit_slip(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.initializeData(false)
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    },
  }
</script>
